<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'app',
  components: {

  },
  data(){
    return{

    }
  }
}
</script>

<style>
html,body,#app {
  width: 100%;
  height: 100%;
  font-family: PingFang SC-Regular, PingFang SC;
  /* font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50; */
}
</style>
