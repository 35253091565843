import instance from "./request"

import req from "./req"


// 登录请求
export const Login = (params) => instance.post("/api/Login/login", params);
// 获取用户可以访问的路由的api
export const getMenu = () => instance.post("/api/Menu/getMenu");
//新增客户
export const saveCustomer = (params) => instance.post("/api/Customer.customer/save", params);
//客户列表
export const customerList = (params) => instance.post("/api/Customer.customer/list", params);
//客户列表状态改变

export const updateStatus = (params) => instance.post("/api/Customer.customer/updateStatus", params);
//充值

export const topUps = (params) => instance.post("/api/Customer.customer/topUp", params);
//获取项目列表
export const getProject = (params) => instance.post("/api/Customer.customer/getProject", params);
//获取线路

export const getLine = () => instance.post("/api/Customer.customer/getLine");
//项目审核
export const auditProject = (params) => instance.post("/api/Customer.customer/auditProject", params);

//操作日志

export const getLog = (params) => instance.post("/api/Customer.customer/getLog", params);
//充值明细

export const getRechargeRecord = (params) => instance.post("/api/Customer.customer/getRechargeRecord", params);
//消费记录

export const getExpenseRecord = (params) => instance.post("/api/Customer.customer/getExpenseRecord", params);

//财务管理顶部数据
export const financeTop = () => instance.post("/api/System.Finance/top");
//财务管理充值列表
export const financeList = (params) => instance.post("/api/System.Finance/list", params);
//财务管理审核

export const financeAudit = (params) => instance.post("/api/System.Finance/audit", params);
//录音任务

export const recordTaskList = (params) => instance.post("/api/CallGather.RecordTask/list", params);

//客户话术-话术录音清单

export const callGatherList = (params) => instance.post("/api/CallGather.CustomerCallGather/list", params);

//录音上传

export const uploadRecordTask = (params) => instance.post("/api/CallGather.RecordTask/upload", params);




//驳回
export const callGatherReject = (params) => instance.post("/api/CallGather.CustomerCallGather/reject", params);
//企业

export const companyGatherList = (params) => instance.post("/api/CallGather.CustomerCallGather/companyGatherList", params);

//录音清单


export const recordList = (params) => instance.post("/api/CallGather.Record/list", params);

//录音启用停用

export const recordListCall = (params) => instance.post("/api/CallGather.Record/list", params);

//获取话术列表
export const getAiCallList = (params) => instance.post("/api/CallGather.Record/list",params);
//获取流程列表
export const getToneBranch = (params) => instance.post("/api/CallGather.Record/getToneBranch",params);
//录音文件列表

// export const recordList = (params) => instance.post("/api/CallGather.Record/recordList",params);
//全部项目

export const  allProject= () => instance.post("/api/CallGather.Record/all");
//新增话术
export const saveGather = (params) => instance.post("/api/CallGather.Record/saveGather",params);
//分支问题列表

export const  problemList= (params) => instance.post("/api/CallGather.Record/problemList",params);
//无应答列表

export const  callNoRespList= (params) => instance.post("/api/CallGather.Record/callNoRespList",params);
//分类设置列表

export const  categoryList= (params) => instance.post("/api/CallGather.Record/categoryList",params);
//操作日志列表

// export const  getLog= (params) => instance.post("/api/CallGather.Record/getLog",params);


//流程列表增改分支
export const saveToneBranch = (params) => instance.post("/api/CallGather.Record/saveToneBranch",params);
//语境

export const branchList = (params) => instance.post("/api/CallGather.Record/branchList",params);
//全部话术列表

export const allCallList = () => instance.post("/api/CallGather.Record/all");
//新增语境
export const saveBranch = (params) => instance.post("/api/CallGather.Record/saveBranch",params);
//删除语境
export const delBranch = (params) => instance.post("/api/CallGather.Record/delBranch",params);
//全部录音列表
export const recordAll = () => instance.post("/api/CallGather.Record/recordAll");

//线路列表

export const  routesList= (params) => req.get("/api/phone/c/routes",{params});

//新增线路/修改线路
export const  addRoutes= (params) => req.post("/api/phone/c/route",params);
//释放线路
export const releaseRoutes= (params) => req.post("/api/phone/c/release",params);
//企业话术集合列表
export const  companyGathersList= (params) => req.get("/api/gather/company_gathers",{params});

//企业话术集合中的话术列表
export const  gatherBranchesList= (params) => req.get("/api/gather/company_gather_branches",{params});

//企业导出
export const  exportedData= (params) => req.get("/api/gather/company_gather_exported_data",{params});

//修改录音人员
export const updateRecorder = (params) => instance.post("/api/CallGather.RecordTask/update",params);

//线索管理

export const  clubList= (params) => req.get("/api/clue/website/c/list",{params});

//编辑跟进人员

export const upfollower = (params) => req.post("/api/clue/website/c/update",params);
