<template>
  <div class="home">
    <div class="content">
      <!-- .box$*8{第$个} -->
      <div class="box1">
      </div>
      <div class="box2">
        <dv-border-box-1>
          <h3>这是标题</h3>
          <dv-active-ring-chart :config="config" style="width:100%;height:100%" />
        </dv-border-box-1>
      </div>
      <div class="box3" >
        <ve-pie :data="chartData" height="100%"  :extend="pieExtend"></ve-pie>
      </div>
      <div class="box4" style="background-color: rgb(35, 18, 145);"></div>
      <div class="box5" style="background-color: rgb(233, 3, 34);"></div>
      <div class="box6" style="background-color: rgb(233, 156, 3);"></div>
      <div class="box7" style="background-color: rgb(3, 179, 233);"></div>
      <div class="box8" >
        <dv-border-box-11 title="xxxx表格"></dv-border-box-11>
      </div>
      <div class="box9" style="background-color: rgb(245,23,156);"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  data(){
    return {
      // DataV的数据
      config:{
        data: [
    {
      name: '周口',
      value: 55
    },
    {
      name: '南阳',
      value: 120
    },
    {
      name: '西峡',
      value: 78
    },
    {
      name: '驻马店',
      value: 66
    },
    {
      name: '新乡',
      value: 80
    }
        ],
        lineWidth: 10,
        digitalFlopStyle: {
          fontSize: 18,
          fill: '#fff'
        }
      },
      // v-charts的数据
      chartData: {
        columns: ['date', 'PV'],
        rows: [
          { 'date': '01-01', 'PV': 1231 },
          { 'date': '01-02', 'PV': 1223 },
          { 'date': '01-03', 'PV': 2123 },
          { 'date': '01-04', 'PV': 4123 },
          { 'date': '01-05', 'PV': 3123 },
          { 'date': '01-06', 'PV': 7123 }
        ]
      },
      // 专门给我们改配置用
      pieExtend:{  // 这个属性很强大，是v-charts专门开放给我们使用，用来书写e-charts的属性
        series:{
          radius:[0,"50%"],
          center: ['50%', '50%'],
          label:{
            color:"#fff"
          }
        },
        legend:{
          textStyle:{
            color:"#fff"
          }
        }
      }
    }
  },
  components: {

  }
}
</script>
<style scoped>
.home{
  height: 100%;
}
.content{
  width: 100%;
  height: 100%;
  /* background-color: #fff; */
  /* 设置网格布局 */
  display: grid;
  /* 设置列数，每一个列所占的份数 */
  grid-template-columns: repeat(3,1fr);
  /* 设置行数，每一个行所占的份数 */
  /* grid-template-rows: repeat(3,1fr); */
  grid-template-rows: 50px 1fr 1fr 1fr;
  /* 网格间距 */
  gap:10px;
}
h3{
  color:#fff;
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
h2{
    text-align: center;
    margin: 0;
    line-height: 50px;
    color:#fff;
    font-size: 22px;
    font-weight: bold;
}
.box1{
  /* 跨列 */
  grid-column: span 3;
}
.box8{
  /* 跨列 */
  grid-column: span 2;
}
</style>
