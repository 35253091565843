import Vue from 'vue'
// 清除默认样式
import "reset-css"

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import "@/assets/icons/index"
import "@/utils/filters"
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
import "./plugins/vcharts"
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    'inline': true,
    'button': true,
    'navbar': true,
    'title': true,
    'toolbar': true,
    'tooltip': true,
    'movable': true,
    'zoomable': true,
    'rotatable': true,
    'scalable': true,
    'transition': true,
    'fullscreen': true,
    'keyboard': true,
    'url': 'data-source'
  }
})
Vue.use(ElementUI);
Vue.use(dataV)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
