import axios from "axios"
import { Message } from 'element-ui'
import router from "@/router"
const instance = axios.create({
    // baseURL:"http://tech.wolfcode.cn:23683",
    // baseURL:"http://10.20.2.143:8088",
    // baseURL: "https://test.api.youganai.com/",
    // baseURL: "https://console.api.youganai.com/",
    baseURL: window.Glod.BaseUrl,
    timeout:10000
})

instance.interceptors.request.use(config=>{
    const token = localStorage.getItem("edb-authorization-token")
    if(token && !config.url.endsWith("/login") && !config.url.endsWith("/captchaImage") ){
        config.headers["Authorization"] =  token
    }
    return config
},err=>{
    return Promise.reject(err)
});

instance.interceptors.response.use(res=>{

    let res_data = res.data
    console.log(res.data);
    // 导出文件的时候，后端没有给我们返回code属性，直接是二进制字符串， 但是数据是没有问题的
    // 所以要让res_data到组件中去处理，不能再这里拦截死，所以要加res_data.code &&
    if(res_data.code && res_data.code!=200){
        Message.error(res_data.msg || '网络请求错误');
        // 只要不是200，都会走这里
        if(res_data.code==401){
            // 401一般表示token过期或者没有带
            localStorage.removeItem("edb-authorization-token");
            router.push("/login")
        }



        // 这里return不是为了结束函数，实际上是把return值传到组件中的res
        return false
    }

    return res_data
},err=>{
    return Promise.reject(err)
});

export default instance
