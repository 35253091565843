import Vue from 'vue'
import Vuex from 'vuex'
import navCollapse from './navCollapse'
import userMenuData from './userMenuData'
import userInfo from './userInfo'
import {categoryList, problemList} from "@/request/api";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    refreshChild: false, // 刷新子组件的标志
    // upUrl:'https://console.api.youganai.com/',
    // upUrl:'https://test.api.youganai.com/',
    upUrl: window.Glod.BaseUrl,
    data: '',
    rightIndex:'1',
    processList:[],
    voiceList:[],
    blackGroupId:'',
    blackHistoryList:[],
    taskId:'',
    callOut:{},
    noCallList:[],
    calledList:[],
    problemList:[],
    noRespList:[],
    trickData:{},
    cateList:[],
    logList:[],
    taskDetail:{},
    blackHistoryTotal:null,
    noCallListTotal:null,
    calledListTotal:null,
    logListTotal:null,
    voiceListTotal:null,
    problemListTotal:null,
    noRespListTotal:null
  },

  mutations: {
    setRefreshChild(state, value) {
      state.refreshChild = value;
    },
    updateData(state, newData) {
      state.data = newData;
    },
    uprightIndexData(state, newData) {
      state.rightIndex = newData;
    },
    upprocessList(state, newData) {
      state.processList = newData;
    },
    upvoiceList(state, newData) {
      state.voiceList = newData;
    },
    upvoiceListTotal(state, newData) {
      state.voiceListTotal = newData;
    },
    upblackGroupId(state, newData) {
      state.blackGroupId = newData;
    },

    upblackHistoryList(state, newData) {
      state. blackHistoryList = newData;
    },
    upblackHistoryTotal(state, newData) {
      state.blackHistoryTotal = newData;
    },
    uptaskId(state, newData) {
      state.taskId = newData;
    },

    upcallOut(state, newData) {
      state.callOut = newData;
    },

    upnoCallList(state, newData) {
      state.noCallList = newData;
    },
    upnoCallListTotal(state, newData) {
      state.noCallListTotal = newData;
    },
    upcalledList(state, newData) {
      state.calledList = newData;
    },
    upcalledListTotal(state, newData) {
      state.calledListTotal = newData;
    },


    upproblemList(state, newData) {
      state.problemList = newData;
    },
    upproblemListTotal(state, newData) {
      state.problemListTotal = newData;
    },
    upnoRespList(state, newData) {
      state.noRespList = newData;
    },
    upnoRespListTotal(state, newData) {
      state.noRespListTotal = newData;
    },
    uptrickData(state, newData) {
      state.trickData = newData;
    },
    upcateList(state, newData) {
      state.cateList = newData;
    },

    uplogList(state, newData) {
      state.logList = newData;
    },
    uplogListTotal(state, newData) {
      state.logListTotal = newData;
    },
    uptaskDetail(state, newData) {
      state.taskDetail = newData;
    },

  },
  actions: {
    triggerRefreshChild({ commit }) {
      commit('setRefreshChild', true);
    },
  },
  modules: {
    navCollapse,userMenuData,userInfo
  }
})
